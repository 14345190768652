<template>
  <div>
    <b-table striped small hover :items="issuePhonsData">
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'IssuePhonDetails', params: {
            id: data.item.id
          }}">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode"
            @click="editRow($event, data.item.action.issuePhon)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
    </b-table>
    <b-col sm="4" md="3" v-if="showTotalLable">
      Total {{ issuePhonsData.length }} {{ issuePhonsData.length === 1 ? 'entry' : "entries" }} in table
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'IssuePhonTable',
    props: ['issuePhons', 'editMode', 'showTotal'],
    data(){
      return {
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    components: {
      tdColUUID
    },
    computed: {
      issuePhonsData(){
        return this.issuePhons.map((issuePhon, index) => {
          issuePhon['_index'] = index;
          return {
            id: issuePhon.id,
            form: issuePhon.form,
            comment: issuePhon.comment,
            ordering: issuePhon.ordering,
            action: {
              issuePhon: issuePhon
            },
          }
        }).sort((a, b) => {
          if (a.ordering < b.ordering) return -1;
          if (a.ordering > b.ordering) return 1;
          return 0;
        })
      }
    },
    methods: {
      deleteRow(event, id){
        event.preventDefault()
        this.$emit('delete', id)
      },
      editRow(event, issuePhon) {
        event.preventDefault();
        this.$emit("edit", issuePhon);
      },
    }
  }
</script>
