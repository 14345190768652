<template>
  <div class="details">
    <div v-if="loading || !head">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
          <b-col class="mt-5">
            <div class="spinner-border m-5" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle
              v-bind:title="head.form"
              :pretitle="'Head details'"
            />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button class="btn-legam-xs mb-2 btn-secondary" v-b-modal.modal-create-hcc>
                  <b-icon icon="plus-circle-fill"></b-icon>
                  Generate HCC
                </button>
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button
                  v-if="$store.getters.checkRole('legam.scripta.head.delete')"
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else-if="config.editable">
                <!-- <button
                  @click="showHCCs"
                  class="btn-legam-xs mb-2"
                >
                  <b-icon icon="list"></b-icon>
                  Show HCCs
                </button> -->
                <button
                  @click="editMode = true"
                  v-if="$store.getters.checkRole('legam.scripta.head.modify')"
                  class="btn-legam-xs mb-2"
                >
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button
                  v-if="
                    !$store.getters.checkRole('legam.scripta.head.modify') &&
                    $store.getters.checkRole('legam.scripta.head.delete')
                  "
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Form</h6>
                  <b-form-input
                    id="form"
                    v-model="head.form"
                    placeholder="Form"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="head.form"></span>
                </b-col>
                <b-col>
                  <h6>Comment</h6>
                  <b-form-input
                    id="comment"
                    v-model="head.comment"
                    placeholder="Comment"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="head.comment"></span>
                </b-col>
              </b-row>

              <hr>


              <b-tabs content-class="mt-3">

                <b-tab title="HCCs" active>
                  <b-button @click="showHCCs = !showHCCs" size="sm" class="mb-2 mt-4">
                    <span v-if="showHCCs">Hide Head Category Configurations</span>
                    <span v-else>Show Head Category Configurations</span>
                  </b-button>

                  <div v-show="showHCCs">
                    <HCCList :headId="head.id" v-if="showHCCs" />
                  </div>
                </b-tab>

                <b-tab title="Head Taggings (Types)">
                  <b-button @click="showHeadTaggingsTypes = !showHeadTaggingsTypes" size="sm" class="mb-2 mt-4 mr-2">
                    <span v-if="showHeadTaggingsTypes">Hide Head Taggings (Types)</span>
                    <span v-else>Show Head Taggings (Types)</span>
                  </b-button>

                  <b-button @click="downloadCSVTaggingsTypes" size="sm" class="mb-2 mt-4">
                    <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
                    Download Taggings (.csv)
                  </b-button>

                  <div v-show="showHeadTaggingsTypes">
                    <HeadTaggingsTypesList
                      :headId="head.id"
                      :data="HeadTaggingsTypesData"
                      v-if="showHeadTaggingsTypes"
                      :key="indexHeadTaggingsTypes"
                      />
                  </div>
                </b-tab>

                <b-tab title="Head Taggings (absolute Occurrences)">
                  <!-- <b-button @click="showHeadTaggingsAbsoluteOccs = !showHeadTaggingsAbsoluteOccs" size="sm" class="mb-2 mt-4 mr-2">
                    <span v-if="showHeadTaggingsAbsoluteOccs">Hide Head Taggings (absolute Occurrences)</span>
                    <span v-else>Show Head Taggings (absolute Occurrences)</span>
                  </b-button> -->

                  <b-button @click="downloadCSVTaggingsAbsoluteOccs" size="sm" class="mb-2 mt-4">
                    <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
                    Download Taggings (.csv)
                  </b-button>

                  <div v-show="showHeadTaggingsAbsoluteOccs">
                    <HeadTaggingsAbsoluteOccsList
                      :headId="head.id"
                      :data="HeadTaggingsAbsoluteOccsData"
                      v-if="showHeadTaggingsAbsoluteOccs"
                      :key="indexHeadTaggingsAbsoluteOccs"
                      />
                  </div>
                </b-tab>

              </b-tabs>


              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-8 class="text-left">
                      Phonol Envs <small>({{ head.phonolEnvs.length }} {{ head.phonolEnvs.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-8" accordion="my-accordion-8" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-phonolenv>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-phonolenv" title="Add Phonol Env" ok-only ok-variant="secondary" @ok="addPhonolEnv" size="lg">
                        <PhonolEnvAutocomplete
                          :phonolEnv="modalPhonolEnv"
                          :showOrdering="true"
                          :exclude="head.phonolEnvs.map(phonolEnv => phonolEnv.id)"
                          :orderingNum="head.phonolEnvs.length + 1"
                        />
                      </b-modal>
                      <PhonolEnvTable
                        :showTotal="false"
                        :phonolEnvs="head.phonolEnvs"
                        @delete="deletePhonolEnv"
                        @edit="editPhonolEnv"
                        :editMode="editMode"
                        v-if="head.phonolEnvs.length"
                        :key="indexPhonolEnv"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="text-left">
                      Base Etyms <small>({{ head.baseEtyms.length }} {{ head.baseEtyms.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-baseetym>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-baseetym" title="Add Base Etym" ok-only ok-variant="secondary" @ok="addBaseEtym" size="lg">
                        <BaseEtymAutocomplete
                          :baseEtym="modalBaseEtym"
                          :showOrdering="true"
                          :exclude="head.baseEtyms.map(baseEtym => baseEtym.id)"
                          :orderingNum="head.baseEtyms.length + 1"
                        />
                      </b-modal>
                      <BaseEtymTable
                        :showTotal="false"
                        :baseEtyms="head.baseEtyms"
                        @delete="deleteBaseEtym"
                        @edit="editBaseEtym"
                        :editMode="editMode"
                        v-if="head.baseEtyms.length"
                        :key="indexBaseEtym"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 class="text-left">
                      Base Etym Vars <small>({{ head.baseEtymVars.length }} {{ head.baseEtymVars.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-baseetymvar>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-baseetymvar" title="Add Base Etym Var" ok-only ok-variant="secondary" @ok="addBaseEtymVar" size="lg">
                        <BaseEtymVarAutocomplete
                          :baseEtymVar="modalBaseEtymVar"
                          :showOrdering="true"
                          :exclude="head.baseEtymVars.map(baseEtymVar => baseEtymVar.id)"
                          :orderingNum="head.baseEtymVars.length + 1"
                        />
                      </b-modal>
                      <BaseEtymVarTable
                        :showTotal="false"
                        :baseEtymVars="head.baseEtymVars"
                        @delete="deleteBaseEtymVar"
                        @edit="editBaseEtymVar"
                        :editMode="editMode"
                        v-if="head.baseEtymVars.length"
                        :key="indexBaseEtymVar"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 class="text-left">
                      Base Indiv <small>({{ head.baseIndivs.length }} {{ head.baseIndivs.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-baseindiv>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-baseindiv" title="Add Base Indiv" ok-only ok-variant="secondary" @ok="addBaseIndiv" size="lg">
                        <BaseIndivAutocomplete
                          :baseIndiv="modalBaseIndiv"
                          :showOrdering="true"
                          :exclude="head.baseIndivs.map(baseIndiv => baseIndiv.id)"
                          :orderingNum="head.baseIndivs.length + 1"
                        />
                      </b-modal>
                      <BaseIndivTable
                        :showTotal="false"
                        :baseIndivs="head.baseIndivs"
                        @delete="deleteBaseIndiv"
                        @edit="editBaseIndiv"
                        :editMode="editMode"
                        v-if="head.baseIndivs.length"
                        :key="indexBaseIndiv"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-4 class="text-left">
                      Base Special <small>({{ head.baseSpecials.length }} {{ head.baseSpecials.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-basespecial>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-basespecial" title="Add Base Special" ok-only ok-variant="secondary" @ok="addBaseSpecial" size="lg">
                        <BaseSpecialAutocomplete
                          :baseSpecial="modalBaseSpecial"
                          :showOrdering="true"
                          :exclude="head.baseSpecials.map(baseSpecial => baseSpecial.id)"
                          :orderingNum="head.baseSpecials.length + 1"
                        />
                      </b-modal>
                      <BaseSpecialTable
                        :showTotal="false"
                        :baseSpecials="head.baseSpecials"
                        @delete="deleteBaseSpecial"
                        @edit="editBaseSpecial"
                        :editMode="editMode"
                        v-if="head.baseSpecials.length"
                        :key="indexBaseSpecial"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-6 class="text-left">
                      Issue Types <small>({{ head.issueTypes.length }} {{ head.issueTypes.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-6" accordion="my-accordion-6" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-issuetype>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-issuetype" title="Add Issue Type" ok-only ok-variant="secondary" @ok="addIssueType" size="lg">
                        <IssueTypeAutocomplete
                          :issueType="modalIssueType"
                          :showOrdering="true"
                          :exclude="head.issueTypes.map(issueType => issueType.id)"
                          :orderingNum="head.issueTypes.length + 1"
                        />
                      </b-modal>
                      <IssueTypeTable
                        :showTotal="false"
                        :issueTypes="head.issueTypes"
                        @delete="deleteIssueType"
                        @edit="editIssueType"
                        :editMode="editMode"
                        v-if="head.issueTypes.length"
                        :key="indexIssueType"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-7 class="text-left">
                      Issue Vars <small>({{ head.issueVars.length }} {{ head.issueVars.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-7" accordion="my-accordion-7" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-issuevar>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-issuevar" title="Add Issue Var" ok-only ok-variant="secondary" @ok="addIssueVar" size="lg">
                        <IssueVarAutocomplete
                          :issueVar="modalIssueVar"
                          :showOrdering="true"
                          :exclude="head.issueVars.map(issueVar => issueVar.id)"
                          :orderingNum="head.issueVars.length + 1"
                        />
                      </b-modal>
                      <IssueVarTable
                        :showTotal="false"
                        :issueVars="head.issueVars"
                        @delete="deleteIssueVar"
                        @edit="editIssueVar"
                        :editMode="editMode"
                        v-if="head.issueVars.length"
                        :key="indexIssueVar"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-5 class="text-left">
                      Issue Phone <small>({{ head.issuePhons.length }} {{ head.issuePhons.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-5" accordion="my-accordion-5" role="tabpanel">
                    <b-card-body>
                      <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-issuephon>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span>
                      <b-modal id="modal-add-issuephon" title="Add Issue Phon" ok-only ok-variant="secondary" @ok="addIssuePhon" size="lg">
                        <IssuePhonAutocomplete
                          :issuePhon="modalIssuePhon"
                          :showOrdering="true"
                          :exclude="head.issuePhons.map(issuePhon => issuePhon.id)"
                          :orderingNum="head.issuePhons.length + 1"
                        />
                      </b-modal>
                      <IssuePhonTable
                        :showTotal="false"
                        :issuePhons="head.issuePhons"
                        @delete="deleteIssuePhon"
                        @edit="editIssuePhon"
                        :editMode="editMode"
                        v-if="head.issuePhons.length"
                        :key="indexIssuePhon"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </article>
          </b-col>
        </b-row>
        <div class="mt-4" v-if="false">
          <h6>Logs</h6>
          <ChangeLogsTable
            :objectType="'head'"
            :objectId="id"
            :key="'logs' + index"
          />
        </div>
      </b-container>
    </div>
    <b-modal id="modal-create-hcc" title="Create HCC" ok-only ok-variant="secondary" @ok="createHCC" size="xl">
      <HCCCreate :head="head" ref="HCCCreateRef" />
    </b-modal>
  </div>
</template>

<style scoped>
* >>> th {
  font-size: 10px !important;
  text-transform: uppercase;
  color: #bbbbbb;
}
</style>

<script>
import { mapState } from "vuex";
import router from "@/router";
import PageTitle from "@/components/PageTitle.vue";
import ChangeLogsTable from "@/components/ChangeLogsTable.vue";
import BaseEtymTable from "@/components/Scripta/BaseEtymTable.vue";
import BaseEtymAutocomplete from "@/components/Scripta/BaseEtymAutocomplete.vue";
import BaseEtymVarTable from "@/components/Scripta/BaseEtymVarTable.vue";
import BaseEtymVarAutocomplete from "@/components/Scripta/BaseEtymVarAutocomplete.vue";
import BaseIndivTable from "@/components/Scripta/BaseIndivTable.vue";
import BaseIndivAutocomplete from "@/components/Scripta/BaseIndivAutocomplete.vue";
import BaseSpecialTable from "@/components/Scripta/BaseSpecialTable.vue";
import BaseSpecialAutocomplete from "@/components/Scripta/BaseSpecialAutocomplete.vue";
import IssuePhonTable from "@/components/Scripta/IssuePhonTable.vue";
import IssuePhonAutocomplete from "@/components/Scripta/IssuePhonAutocomplete.vue";
import IssueTypeTable from "@/components/Scripta/IssueTypeTable.vue";
import IssueTypeAutocomplete from "@/components/Scripta/IssueTypeAutocomplete.vue";
import IssueVarTable from "@/components/Scripta/IssueVarTable.vue";
import IssueVarAutocomplete from "@/components/Scripta/IssueVarAutocomplete.vue";
import PhonolEnvTable from "@/components/Scripta/PhonolEnvTable.vue";
import PhonolEnvAutocomplete from "@/components/Scripta/PhonolEnvAutocomplete.vue";
import HCCCreate from "@/components/Scripta/HCCCreate.vue";
import HCCList from "@/components/Scripta/HCCList";
import HeadTaggingsTypesList from "@/components/Scripta/HeadTaggingsTypesList";
import HeadTaggingsAbsoluteOccsList from "@/components/Scripta/HeadTaggingsAbsoluteOccsList";
import HelpStore from '@/store/helpstore.js'
import config from '@/config.js'

export default {
  name: "HeadDeatils",
  data() {
    return {
      config: config,
      id: this.$route.params.id,
      loading: true,
      editMode: false,
      index: 0,
      showHCCs: false,
      showHeadTaggingsTypes: false,
      HeadTaggingsTypesData: null,
      indexHeadTaggingsTypes: 0,
      showHeadTaggingsAbsoluteOccs: false,
      HeadTaggingsAbsoluteOccsData: null,
      indexHeadTaggingsAbsoluteOccs: 0,
      modalBaseEtym: null,
      indexBaseEtym: 1,
      modalBaseEtymVar: null,
      indexBaseEtymVar: 1,
      modalBaseIndiv: null,
      indexBaseIndiv: 1,
      modalBaseSpecial: null,
      indexBaseSpecial: 1,
      modalIssuePhon: null,
      indexIssuePhon: 1,
      modalIssueType: null,
      indexIssueType: 1,
      modalIssueVar: null,
      indexIssueVar: 1,
      modalPhonolEnv: null,
      indexPhonolEnv: 1,
    };
  },
  components: {
    PageTitle,
    ChangeLogsTable,
    BaseEtymTable,
    BaseEtymAutocomplete,
    BaseEtymVarTable,
    BaseEtymVarAutocomplete,
    BaseIndivTable,
    BaseIndivAutocomplete,
    BaseSpecialTable,
    BaseSpecialAutocomplete,
    IssuePhonTable,
    IssuePhonAutocomplete,
    IssueTypeTable,
    IssueTypeAutocomplete,
    IssueVarTable,
    IssueVarAutocomplete,
    PhonolEnvTable,
    PhonolEnvAutocomplete,
    HCCCreate,
    HCCList,
    HeadTaggingsTypesList,
    HeadTaggingsAbsoluteOccsList
  },
  computed: {
    ...mapState({
      head: (state) => state.head.head,
    }),
  },
  mounted() {
    this.$store.dispatch("loadHead", this.id);
  },
  watch: {
    id() {
      this.loading = true;
      this.$store.dispatch("loadHead", this.id);
    },
    head() {
      this.loading = false;
    },
    showHeadTaggingsTypes() {
      if (this.showHeadTaggingsTypes == true && this.HeadTaggingsTypesData == null) {
        this.$store.dispatch("loadHeadTaggingsTypes", this.id).then(data => {
          this.HeadTaggingsTypesData = data.data
          this.indexHeadTaggingsTypes++
        })
      }
    },
    showHeadTaggingsAbsoluteOccs() {
      if (this.showHeadTaggingsAbsoluteOccs == true && this.HeadTaggingsAbsoluteOccsData == null) {
        this.$store.dispatch("loadHeadTaggingsAbsoluteOccs", this.id).then(data => {
          this.HeadTaggingsAbsoluteOccsData = data.data
          this.indexHeadTaggingsAbsoluteOccs++
        })
      }
    },
  },
  methods: {
    createHCC(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.$refs.HCCCreateRef.validate()){
        this.$store.dispatch("saveHCC", HelpStore.item).then(() => {
          this.$bvModal.hide("modal-create-hcc")
          this.$store.dispatch("addNotification", {
            type: "success",
            text: `HCCs created`,
          });
        });
      }
      else {
        this.$store.dispatch("addNotification", {
          type: "warning",
          text: `Enter all required data`,
        });
      }
    },
    cancelEdit() {
      this.editMode = false;
      this.$store.dispatch("loadHead", this.id);
      this.$store.dispatch("addNotification", {
        type: "warning",
        text: `Changes dismissed`,
      });
    },
    saveEdit() {
      this.editMode = false;
      this.$store.dispatch("saveHead", this.head).then(() => {
        this.$store.dispatch("addNotification", {
          type: "success",
          text: `Changes saved`,
        });
        this.index++;
      });
    },
    deleteItem() {
      this.$bvModal.msgBoxConfirm("Are you sure?").then((value) => {
        if (value) {
          this.$store.dispatch("deleteHead", this.head).then(() => {
            router.push("/heads");
          });
        }
      });
    },

    addBaseEtym(){
      this.modalBaseEtym = null;
      if (HelpStore.item){
        this.$bvModal.hide('modal-add-baseetym')

        if (HelpStore.item._index != null){
          this.head.baseEtyms[HelpStore.item._index] = HelpStore.item;
        }
        else {
          this.head.baseEtyms.push(HelpStore.item);
        }
        this.indexBaseEtym++;
      }
    },
    editBaseEtym(baseEtym){
      this.modalBaseEtym = baseEtym;
      this.$bvModal.show('modal-add-baseetym');
    },
    deleteBaseEtym(baseEtymId){
      this.head.baseEtyms = this.head.baseEtyms.filter(baseEtym => baseEtym.id != baseEtymId)
    },

    addBaseEtymVar(){
      this.modalBaseEtymVar = null;
      if (HelpStore.item){
        this.$bvModal.hide('modal-add-baseetymvar')

        if (HelpStore.item._index != null){
          this.head.baseEtymVars[HelpStore.item._index] = HelpStore.item;
        }
        else {
          this.head.baseEtymVars.push(HelpStore.item);
        }
        this.indexBaseEtymVar++;
      }
    },
    editBaseEtymVar(baseEtymVar){
      this.modalBaseEtymVar = baseEtymVar;
      this.$bvModal.show('modal-add-baseetymvar');
    },
    deleteBaseEtymVar(baseEtymVarId){
      this.head.baseEtymVars = this.head.baseEtymVars.filter(baseEtymVar => baseEtymVar.id != baseEtymVarId)
    },

    addBaseIndiv(){
      this.modalBaseIndiv = null;
      if (HelpStore.item){
        this.$bvModal.hide('modal-add-baseindiv')

        if (HelpStore.item._index != null){
          this.head.baseIndivs[HelpStore.item._index] = HelpStore.item;
        }
        else {
          this.head.baseIndivs.push(HelpStore.item);
        }
        this.indexBaseIndiv++;
      }
    },
    editBaseIndiv(baseIndiv){
      this.modalBaseIndiv = baseIndiv;
      this.$bvModal.show('modal-add-baseindiv');
    },
    deleteBaseIndiv(baseIndivId){
      this.head.baseIndivs = this.head.baseIndivs.filter(baseIndiv => baseIndiv.id != baseIndivId)
    },

    addBaseSpecial(){
      this.modalBaseSpecial = null;
      if (HelpStore.item){
        this.$bvModal.hide('modal-add-basespecial')

        if (HelpStore.item._index != null){
          this.head.baseSpecials[HelpStore.item._index] = HelpStore.item;
        }
        else {
          this.head.baseSpecials.push(HelpStore.item);
        }
        this.indexBaseSpecial++;
      }
    },
    editBaseSpecial(baseSpecial){
      this.modalBaseSpecial = baseSpecial;
      this.$bvModal.show('modal-add-basespecial');
    },
    deleteBaseSpecial(baseSpecialId){
      this.head.baseSpecials = this.head.baseSpecials.filter(baseSpecial => baseSpecial.id != baseSpecialId)
    },

    addIssuePhon(){
      this.modalIssuePhon = null;
      if (HelpStore.item){
        this.$bvModal.hide('modal-add-issuephon')

        if (HelpStore.item._index != null){
          this.head.issuePhons[HelpStore.item._index] = HelpStore.item;
        }
        else {
          this.head.issuePhons.push(HelpStore.item);
        }
        this.indexIssuePhon++;
      }
    },
    editIssuePhon(issuePhon){
      this.modalIssuePhon = issuePhon;
      this.$bvModal.show('modal-add-issuephon');
    },
    deleteIssuePhon(issuePhonId){
      this.head.issuePhons = this.head.issuePhons.filter(issuePhon => issuePhon.id != issuePhonId)
    },

    addIssueType(){
      this.modalIssueType = null;
      if (HelpStore.item){
        this.$bvModal.hide('modal-add-issuetype')

        if (HelpStore.item._index != null){
          this.head.issueTypes[HelpStore.item._index] = HelpStore.item;
        }
        else {
          this.head.issueTypes.push(HelpStore.item);
        }
        this.indexIssueType++;
      }
    },
    editIssueType(issueType){
      this.modalIssueType = issueType;
      this.$bvModal.show('modal-add-issuetype');
    },
    deleteIssueType(issueTypeId){
      this.head.issueTypes = this.head.issueTypes.filter(issueType => issueType.id != issueTypeId)
    },

    addIssueVar(){
      this.modalIssueVar = null;
      if (HelpStore.item){
        this.$bvModal.hide('modal-add-issuevar')

        if (HelpStore.item._index != null){
          this.head.issueVars[HelpStore.item._index] = HelpStore.item;
        }
        else {
          this.head.issueVars.push(HelpStore.item);
        }
        this.indexIssueVar++;
      }
    },
    editIssueVar(issueVar){
      this.modalIssueVar = issueVar;
      this.$bvModal.show('modal-add-issuevar');
    },
    deleteIssueVar(issueVarId){
      this.head.issueVars = this.head.issueVars.filter(issueVar => issueVar.id != issueVarId)
    },

    addPhonolEnv(){
      this.modalPhonolEnv = null;
      if (HelpStore.item){
        this.$bvModal.hide('modal-add-phonolenv')

        if (HelpStore.item._index != null){
          this.head.phonolEnvs[HelpStore.item._index] = HelpStore.item;
        }
        else {
          this.head.phonolEnvs.push(HelpStore.item);
        }
        this.indexPhonolEnv++;
      }
    },
    editPhonolEnv(phonolEnv){
      this.modalPhonolEnv = phonolEnv;
      this.$bvModal.show('modal-add-phonolenv');
    },
    deletePhonolEnv(phonolEnvId){
      this.head.phonolEnvs = this.head.phonolEnvs.filter(phonolEnv => phonolEnv.id != phonolEnvId)
    },


    toCSV(table) {
      return table
        .map(row => row.map(cell => {
            // We remove blanks and check if the column contains
            // other whitespace,`,` or `"`.
            // In that case, we need to quote the column.
            if (cell === null) {
              return ""
            }
            cell = cell.toString().replace(/#/g, ".")
            if (cell.replace(/ /g, '').match(/[\s,"]/)) {
                return '"' + cell.replace(/"/g, '""') + '"';
            }
            return cell;
          }).join(',')
        )
        .join('\n');
    },
    async downloadCSVTaggingsTypes() {
      if (this.HeadTaggingsTypesData == null) {
        await this.$store.dispatch("loadHeadTaggingsTypes", this.id).then(data => {
          this.HeadTaggingsTypesData = data.data
          this.indexHeadTaggingsTypes++
        })
      }

      const rows = [
        ["HCC code", "Shortname", "Total # Taggings", "Types"],
        ...this.HeadTaggingsTypesData.map(row => [
          row.hcc_code,
          row.description,
          row.total,
          row.types
        ])
      ]

      // let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join("\t")).join("\n");
      let csvContent = "data:text/csv;charset=utf-8," + this.toCSV(rows);
      var encodedUri = encodeURI(csvContent);
      window.open(encodedUri);
    },
    async downloadCSVTaggingsAbsoluteOccs() {
      if (this.HeadTaggingsAbsoluteOccsData == null) {
        await this.$store.dispatch("loadHeadTaggingsAbsoluteOccs", this.id).then(data => {
          this.HeadTaggingsAbsoluteOccsData = data.data
          this.indexHeadTaggingsAbsoluteOccs++
        })
      }

      const rows = [
        [
          "HCC code",
          "Shortname",
          "Issue (Type)",
          "Total # Taggings",
          "chRoyV",
          "chPrevDV",
          "chSaintLR",
          "chPoitLR",
          "chAnjTourLR",
          "docNormM",
          "chOiseC-B",
          "chDoMB",
          "chFlanM",
          "chHainR",
          "chAubC",
          "chMaK",
          "chHMG",
          "chMeM",
          "chMoPBR",
          "chMMAG",
          "chVL",
          "chHSM",
          "chNA",
          "chCOrN",
          "chSLA",
          "chDoubsL",
          "chDoubsD",
          "chJuM",
          "docAinP",
          "docJuBeSSMG",
          "chNChSMKGF",
          "docFribF",
          "docVauGeG",
          "docForezG",
          "docLyonD",
          "CodiRT",
          "docGrenD",
          "docHAlpM",
          "docAlpHPrM",
          "docAlpMM",
          "docAixPP",
          "docArlesB",
          "docMarsP",
          "chOccBr"
        ],
        ...this.HeadTaggingsAbsoluteOccsData.map(row => [
          row.hcc_code,
          row.description,
          row.issue_type,
          row.total,
          row.chRoyV,
          row.chPrevDV,
          row.chSaintLR,
          row.chPoitLR,
          row.chAnjTourLR,
          row.docNormM,
          row.chOiseCB,
          row.chDoMB,
          row.chFlanM,
          row.chHainR,
          row.chAubC,
          row.chMaK,
          row.chHMG,
          row.chMeM,
          row.chMoPBR,
          row.chMMAG,
          row.chVL,
          row.chHSM,
          row.chNA,
          row.chCOrN,
          row.chSLA,
          row.chDoubsL,
          row.chDoubsD,
          row.chJuM,
          row.docAinP,
          row.docJuBeSSMG,
          row.chNChSMKGF,
          row.docFribF,
          row.docVauGeG,
          row.docForezG,
          row.docLyonD,
          row.CodiRT,
          row.docGrenD,
          row.docHAlpM,
          row.docAlpHPrM,
          row.docAlpMM,
          row.docAixPP,
          row.docArlesB,
          row.docMarsP,
          row.docMarsP
        ])
      ]

      // let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join("\t")).join("\n");
      let csvContent = "data:text/csv;charset=utf-8," + this.toCSV(rows);
      var encodedUri = encodeURI(csvContent);
      window.open(encodedUri);
    }
  },
};
</script>
