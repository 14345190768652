import { render, staticRenderFns } from "./IssueTypeTable.vue?vue&type=template&id=287ee492&scoped=true"
import script from "./IssueTypeTable.vue?vue&type=script&lang=js"
export * from "./IssueTypeTable.vue?vue&type=script&lang=js"
import style0 from "./IssueTypeTable.vue?vue&type=style&index=0&id=287ee492&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "287ee492",
  null
  
)

export default component.exports