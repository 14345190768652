<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-5">
        <b-col>
          <b-form-checkbox v-model="defaultOrder" name="check-button" switch size="sm" class="mb-4">
            Default order (when turned on ignores table column order)
          </b-form-checkbox>
          <LegamDataTables
            v-bind="$data"
            :key="indexTable"
            :additionalData="tableAdditionalData"
            @click="duplicateHCC"
            :additionalSettings="additionalSettings"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-duplicate-hcc"
      title="Duplicate Head Category Config"
      ok-title="Save"
      @ok="saveHCC"
      size="lg"
    >
      <HCCCreate :hccFromId="hccFromId" ref="HCCCreateRef" />
    </b-modal>
  </div>
</template>

<style scoped>
* >>> .tdAction {
  width: 100px;
}
</style>

<script>
import LegamDataTables from "@/components/LegamDataTables.vue";
import tdColAction from '@/components/tdColAction.vue'
import HCCCreate from '@/components/Scripta/HCCCreate.vue'
import HelpStore from "@/store/helpstore.js";

export default {
  name: "HCCList",
  props: ["headId"],
  data() {
    return {
      hccFromId: null,
      defaultOrder: false,
      indexTable: 0,
      additionalSettings: {
        scrollX: true,
      },
      columns: [
        {
          title: "#Tags",
          field: "numberTaggings",
          sortable: true,
          searchable: false,
        },
        {
          title: "Code",
          field: "code",
          sortable: true,
          searchable: true,
        },
        {
          title: "Shortname",
          field: "description",
          sortable: true,
          searchable: true,
          noHTML: true,
        },
        {
          title: "Env",
          field: "phonologicalEnvironment",
          sortable: true,
          searchable: true,
        },
        {
          title: "B.Et",
          field: "baseEtymological",
          sortable: true,
          searchable: true,
        },
        {
          title: "B.Et.V",
          field: "baseEtymologicalvariant",
          sortable: true,
          searchable: true,
        },
        {
          title: "B.Ind",
          field: "baseIndividual",
          sortable: true,
          searchable: true,
        },
        {
          title: "B.Spc",
          field: "baseSpecial",
          sortable: true,
          searchable: true,
        },
        {
          title: "Ev",
          field: "evolution",
          sortable: true,
          searchable: true,
        },
        {
          title: "Iss",
          field: "issueType",
          sortable: true,
          searchable: true,
        },
        {
          title: "Iss.V",
          field: "issueVariant",
          sortable: true,
          searchable: true,
        },
        {
          title: "Iss.Ph",
          field: "issuePhonetic",
          sortable: true,
          searchable: true,
        },
        {
          title: "Latin",
          field: "examplesLatin",
          sortable: true,
          searchable: true,
        },
        {
          title: "Romance",
          field: "examplesVernacular",
          sortable: true,
          searchable: true,
        },
        {
          title: "Comment",
          field: "comment",
          sortable: true,
          searchable: true,
        },
        {
          title: "Map Dees",
          field: "dees",
          sortable: true,
          searchable: true,
        },
        {
          title: "Code(s) DocLing",
          field: "doclingCodes",
          sortable: true,
          searchable: true,
        },
        {
          title: "Action",
          field: "action",
          tdComp: tdColAction,
          thClass: "tdAction",
          transformData: function(row){
            return [{
              text: 'Details',
              icon: "list-ul",
              id: row.id,
              to: {
                name: "HCCDetails",
                params: {
                  id: row.id,
                },
              },
            },{
              icon: 'collection',
              text: " ",
              dataId: row.id
            }]
          },
        },
      ],
      url: "/hccs/datatables",
      searchableByColumn: true,
      key: 0,
    };
  },

  components: {
    LegamDataTables,
    HCCCreate,
  },
  watch: {
    defaultOrder(){
      this.indexTable++
    }
  },
  computed: {
    tableAdditionalData(){
      return {
        'headId': this.headId,
        'defaultOrder': this.defaultOrder,
      }
    },
  },
  methods: {
    saveHCC(bvModalEvt) {
      // this.$store.dispatch("saveHCC", HelpStore.item).then(() => {
      //   this.key++
      // });
      bvModalEvt.preventDefault()
      if (this.$refs.HCCCreateRef.validate()){
        this.$store.dispatch("saveHCC", HelpStore.item).then(() => {
          this.$bvModal.hide("modal-duplicate-hcc")
          this.$store.dispatch("addNotification", {
            type: "success",
            text: `HCCs created`,
          });
        });
      }
      else {
        this.$store.dispatch("addNotification", {
          type: "warning",
          text: `Please, enter all required data and check if HCC is not exists already`,
        });
      }
    },
    duplicateHCC(hccId){
      this.hccFromId = hccId
      this.$bvModal.show("modal-duplicate-hcc")
    },
  },
};
</script>
