<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-5">
        <b-col>
          <LegamDataTables v-bind="$data" />
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<style scoped>
* >>> .tdAction {
  width: 100px;
}
</style>

<script>
import LegamDataTables from "@/components/LegamDataTables.vue";
// import tdColAction from '@/components/tdColAction.vue'
// import HelpStore from "@/store/helpstore.js";

export default {
  name: "HeadTaggingsTypesList",
  props: ["headId", "data"],
  data() {
    return {
      columns: [
        {
          title: "Code",
          field: "hcc_code",
          sortable: true,
          searchable: true,
        },
        {
          title: "Shortname",
          field: "description",
          sortable: true,
          searchable: true,
        },
        {
          title: "Total # Taggings",
          field: "total",
          sortable: true,
          searchable: false,
        },
        {
          title: "Types",
          field: "types",
          sortable: false,
          searchable: true,
        },
      ],
      JSONData: this.data,
      searchableByColumn: true,
      // key: 0,
    };
  },

  created() {
    // console.log(this.columns)
  },

  components: {
    LegamDataTables
  },
  watch: {
  },
  computed: {
  },
  methods: {
  }
};
</script>

