<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-5">
        <b-col>
          <LegamDataTables v-bind="$data" />
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<style scoped>
* >>> .tdAction {
  width: 100px;
}
</style>

<script>
import LegamDataTables from "@/components/LegamDataTables.vue";
// import tdColAction from '@/components/tdColAction.vue'
// import HelpStore from "@/store/helpstore.js";

export default {
  name: "HeadTaggingsAbsoluteOccsList",
  props: ["headId", "data"],
  data() {
    return {
      columns: [
        {
          title: "Code",
          field: "hcc_code",
          sortable: true,
          searchable: true,
        },
        {
          title: "Shortname",
          field: "description",
          sortable: true,
          searchable: true,
        },
        {
          title: "Issue (Type)",
          field: "issue_type",
          sortable: true,
          searchable: true,
        },
        {
          title: "Total # Taggings",
          field: "total",
          sortable: true,
          searchable: false,
        },
        {
          title: "chRoyV",
          field: "chRoyV",
          sortable: true,
          searchable: true,
        },
        {
          title: "chPrevDV",
          field: "chPrevDV",
          sortable: true,
          searchable: true,
        },
        {
          title: "chSaintLR",
          field: "chSaintLR",
          sortable: true,
          searchable: true,
        },
        {
          title: "chPoitLR",
          field: "chPoitLR",
          sortable: true,
          searchable: true,
        },
        {
          title: "chAnjTourLR",
          field: "chAnjTourLR",
          sortable: true,
          searchable: true,
        },
        {
          title: "docNormM",
          field: "docNormM",
          sortable: true,
          searchable: true,
        },
        {
          title: "chOiseC-B",
          field: "chOiseCB",
          sortable: true,
          searchable: true,
        },
        {
          title: "chDoMB",
          field: "chDoMB",
          sortable: true,
          searchable: true,
        },
        {
          title: "chFlanM",
          field: "chFlanM",
          sortable: true,
          searchable: true,
        },
        {
          title: "chHainR",
          field: "chHainR",
          sortable: true,
          searchable: true,
        },
        {
          title: "chAubC",
          field: "chAubC",
          sortable: true,
          searchable: true,
        },
        {
          title: "chMaK",
          field: "chMaK",
          sortable: true,
          searchable: true,
        },
        {
          title: "chHMG",
          field: "chHMG",
          sortable: true,
          searchable: true,
        },
        {
          title: "chMeM",
          field: "chMeM",
          sortable: true,
          searchable: true,
        },
        {
          title: "chMoPBR",
          field: "chMoPBR",
          sortable: true,
          searchable: true,
        },
        {
          title: "chMMAG",
          field: "chMMAG",
          sortable: true,
          searchable: true,
        },
        {
          title: "chVL",
          field: "chVL",
          sortable: true,
          searchable: true,
        },
        {
          title: "chHSM",
          field: "chHSM",
          sortable: true,
          searchable: true,
        },
        {
          title: "chNA",
          field: "chNA",
          sortable: true,
          searchable: true,
        },
        {
          title: "chCOrN",
          field: "chCOrN",
          sortable: true,
          searchable: true,
        },
        {
          title: "chSLA",
          field: "chSLA",
          sortable: true,
          searchable: true,
        },
        {
          title: "chDoubsL",
          field: "chDoubsL",
          sortable: true,
          searchable: true,
        },
        {
          title: "chDoubsD",
          field: "chDoubsD",
          sortable: true,
          searchable: true,
        },
        {
          title: "chJuM",
          field: "chJuM",
          sortable: true,
          searchable: true,
        },
        {
          title: "docAinP",
          field: "docAinP",
          sortable: true,
          searchable: true,
        },
        {
          title: "docJuBeSSMG",
          field: "docJuBeSSMG",
          sortable: true,
          searchable: true,
        },
        {
          title: "chNChSMKGF",
          field: "chNChSMKGF",
          sortable: true,
          searchable: true,
        },
        {
          title: "docFribF",
          field: "docFribF",
          sortable: true,
          searchable: true,
        },
        {
          title: "docVauGeG",
          field: "docVauGeG",
          sortable: true,
          searchable: true,
        },
        {
          title: "docForezG",
          field: "docForezG",
          sortable: true,
          searchable: true,
        },
        {
          title: "docLyonD",
          field: "docLyonD",
          sortable: true,
          searchable: true,
        },
        {
          title: "CodiRT",
          field: "CodiRT",
          sortable: true,
          searchable: true,
        },
        {
          title: "docGrenD",
          field: "docGrenD",
          sortable: true,
          searchable: true,
        },
        {
          title: "docHAlpM",
          field: "docHAlpM",
          sortable: true,
          searchable: true,
        },
        {
          title: "docAlpHPrM",
          field: "docAlpHPrM",
          sortable: true,
          searchable: true,
        },
        {
          title: "docAlpMM",
          field: "docAlpMM",
          sortable: true,
          searchable: true,
        },
        {
          title: "docAixPP",
          field: "docAixPP",
          sortable: true,
          searchable: true,
        },
        {
          title: "docArlesB",
          field: "docArlesB",
          sortable: true,
          searchable: true,
        },
        {
          title: "docMarsP",
          field: "docMarsP",
          sortable: true,
          searchable: true,
        },
        {
          title: "chOccBr",
          field: "chOccBr",
          sortable: true,
          searchable: true,
        },
      ],
      JSONData: this.data,
      searchableByColumn: true,
      // key: 0,
    };
  },

  created() {
    // console.log(this.columns)
  },

  components: {
    LegamDataTables
  },
  watch: {
  },
  computed: {
  },
  methods: {
  }
};
</script>

