<template>
  <div>
    <b-table striped small hover :items="issueTypesData">
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'IssueTypeDetails', params: {
            id: data.item.id
          }}">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode"
            @click="editRow($event, data.item.action.issueType)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
    </b-table>
    <b-col sm="4" md="3" v-if="showTotalLable">
      Total {{ issueTypesData.length }} {{ issueTypesData.length === 1 ? 'entry' : "entries" }} in table
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'IssueTypeTable',
    props: ['issueTypes', 'editMode', 'showTotal'],
    data(){
      return {
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    components: {
      tdColUUID
    },
    computed: {
      issueTypesData(){
        return this.issueTypes.map((issueType, index) => {
          issueType['_index'] = index;
          return {
            id: issueType.id,
            form: issueType.form,
            comment: issueType.comment,
            ordering: issueType.ordering,
            action: {
              issueType: issueType
            },
          }
        }).sort((a, b) => {
          if (a.ordering < b.ordering) return -1;
          if (a.ordering > b.ordering) return 1;
          return 0;
        })
      }
    },
    methods: {
      deleteRow(event, id){
        event.preventDefault()
        this.$emit('delete', id)
      },
      editRow(event, issueType) {
        event.preventDefault();
        this.$emit("edit", issueType);
      },
    }
  }
</script>
